import { Col, Row, Container } from "react-bootstrap";
import './MyDiplomasAndCertifications.css';

function MyDiplomasAndCertifications() {
    return (
        <Container className="mt-5 contoured">
            <Row className="text">
                <h1 className="section-title">Diplomas and Certifications</h1>
            </Row>
            <Row className="text">
                <Col sm={4} className="text">
                    <Row className="text-truncate px-3 job-background-even py-3 flex-nowrap flex-nowrap">Bachelor of software engineering</Row>
                    <Row className="text-truncate px-3 py-3 flex-nowrap">College diploma in computer science</Row>
                    <Row className="text-truncate px-3 job-background-even py-3 flex-nowrap">Certification</Row>
                </Col>
                <Col sm={2} md={3} lg={4} xl={4} xxl={4} className="text">
                    <Row className="job-background-even d-flex align-items-center justify-content-center py-3 flex-nowrap">Polytechnique Montreal</Row>
                    <Row className="d-flex align-items-center justify-content-center py-3 flex-nowrap">Cegep Lionel-Groulx</Row>
                    <Row className="job-background-even d-flex align-items-center justify-content-center py-3 flex-nowrap">Secure code warrior</Row>
                </Col>
                <Col sm={4} className="text">
                    <Row className="px-3 job-background-even d-flex justify-content-end py-3 flex-nowrap">2018-2022</Row>
                    <Row className="px-3 d-flex justify-content-end py-3 flex-nowrap">2016-2018</Row>
                    <Row className="px-3 job-background-even d-flex justify-content-end py-3 flex-nowrap">2021</Row>
                </Col>
            </Row>
        </Container>
    );
}

export default MyDiplomasAndCertifications;