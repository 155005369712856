import { Navbar } from "react-bootstrap";
import { Container } from "react-bootstrap";
import { Nav } from "react-bootstrap";
import signature from "../signature.png"
import { useState } from "react";
import { Link } from "react-router-dom";
import "./NavigationBar.css";

function NavigationBar() {
  const [selectedLink, setSelectedLink] = useState("HomePage");
  
  const handleClick = (eventKey: string | null) => {
    if (eventKey != null) {
      setSelectedLink(eventKey);
    }
  }

  return (
    <Navbar expand="lg" className="bg-body-tertiary navBar rounded-bar">
      <Container fluid>
        <Navbar.Brand><img src={signature} alt=""/></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto" onSelect={handleClick}>
              <Nav.Link as={Link} eventKey="HomePage" to="HomePage" className={selectedLink === "HomePage" ? "selected-link" : ""}  >Home</Nav.Link>
              <Nav.Link as={Link} eventKey="Resume" to="Resume" className={selectedLink === "Resume" ? "selected-link" : ""}  >Resume</Nav.Link>
              <Nav.Link as={Link} eventKey="Books" to="Books" className={selectedLink === "Books" ? "selected-link" : ""} >Books</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavigationBar;