import { Col } from "react-bootstrap";
export interface SkillScaleProps {
    numberOfColoredSquares: number;
    level: string;
}

function SkillScale(SkillScaleProps: SkillScaleProps) {
    const { numberOfColoredSquares, level } = SkillScaleProps;
    return (
        <Col sm={4} className="d-flex justify-content-end align-items-centered mb-3">
            <Col>
                <p className="text">{level}</p>        
            </Col>
            <svg width="180" height="30">
                <rect x="0" y="0" width="30" height="30" stroke="black" strokeWidth="2" fill={numberOfColoredSquares > 0 ? "#D3D3D3":"none"} vectorEffect="non-scaling-stroke"/>
                <rect x="50" y="0" width="30" height="30" stroke="black" strokeWidth="2" fill={numberOfColoredSquares > 1 ? "#D3D3D3":"none"} vectorEffect="non-scaling-stroke"/>
                <rect x="100" y="0" width="30" height="30" stroke="black" strokeWidth="2" fill={numberOfColoredSquares > 2 ? "#D3D3D3":"none"} vectorEffect="non-scaling-stroke"/>
                <rect x="150" y="0" width="30" height="30" stroke="black" strokeWidth="2" fill={numberOfColoredSquares > 3 ? "#D3D3D3":"none"} vectorEffect="non-scaling-stroke"/>
            </svg>
        </Col>
    )
}

export default SkillScale;