
import { Container, Row} from "react-bootstrap";
import Skill from "./Skill/Skill";
import skills from "./skills.json";

function Skills() {
    return (
        <Container className="mt-5 contoured">
            <Row className="text px-0">
                <h1 className="section-title">Skills</h1>
            </Row>
            <Row className="text">
                {
                    skills.sort((a, b) => b.numberOfColoredSquares - a.numberOfColoredSquares).map((skill, index) => {
                        return (
                            <Skill key={index} numberOfColoredSquares={skill.numberOfColoredSquares} skills={skill.skills} level={skill.level} index={index}/>
                        )
                    })
                }
            </Row>
        </Container>
    )
}

export default Skills;