import { Card, Col } from "react-bootstrap";

export interface BookProps {
    Title: string,
    Author: string,
    PicturePath: string,
    Description: string,
}

function Book(props: BookProps) {
    const pictureFolder = './books-picture/'
    const logo = require(`${pictureFolder}${props.PicturePath}`);
    return (
        <Col sm={4} className="d-flex justify-content-center mt-5 mb-5">
            <Card style={{ width: '18rem' }}>
                <Card.Img variant="top" src={logo} />
                <Card.Body>
                    <Card.Title>{props.Title}</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">{props.Author}</Card.Subtitle>
                    <Card.Text>
                        {props.Description}
                    </Card.Text>
                </Card.Body>
            </Card>
        </Col>
    );
}

export default Book;