import { Container, Row } from 'react-bootstrap';
import Job from '../Job/Job';
import jobs from './jobs.json';

function MyExperiences() {
  return (
      <Container className="mt-5 contoured">
        <Row className="text px-0 padding-top">
          <h1 className="section-title">My Experiences</h1>
        </Row>
        { 
          jobs.map((job , i) => {
            job.index = i
            return <Job key={i} {...job}></Job>
          })
        }
      </Container>
  );
}

export default MyExperiences