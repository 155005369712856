import { Col, Row, Container } from "react-bootstrap";
import SkillScale from "../SkillScale/SkillScale";

export interface SkillScaleProps {
    numberOfColoredSquares: number;
    skills: string[];
    level: string;
    index: number;
}

function Skill(SkillScaleProps: SkillScaleProps) {
    const backgroundStyle = SkillScaleProps.index % 2 === 0 ? "job-background-even" : "job-background-odd";
    return (
        <Container className={`${backgroundStyle}`}>
            <Row className="mb-3 mt-3 align-items-center">
                <Col className={`text`} sm={8}>
                    {SkillScaleProps.skills.join(", ")}
                </Col>
                <SkillScale numberOfColoredSquares={SkillScaleProps.numberOfColoredSquares} level={SkillScaleProps.level} />
            </Row>
        </Container>
    )
}

export default Skill;