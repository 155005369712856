import { Col, Row } from "react-bootstrap";
import "./Project.css";
export interface ProjectProps {
    JobDescription: string[],
    BulletPointDescription: string[]
    Title: string,
    index: number
}

function Project(props: ProjectProps) {
    const backgroundStyle = props.index % 2 === 0 ? "job-background-even" : "job-background-odd";

    return (
        <>
            <Row className={`job-title text-center ${backgroundStyle}`}>
                {props.Title}
            </Row>
            <Row>
                <Col sm={12} className={`text ${backgroundStyle} project-description`}>
                    <Row className="px-3">
                        {props.JobDescription.map((paragraph, i) => {
                            return <div key={i}>{paragraph}<br/></div>
                        })}
                        <ul>
                            {props.BulletPointDescription.map((paragraph, index) => {
                                return <li className="bullet-point" key={index}>{paragraph}{index}</li>
                            })}
                        </ul>
                    </Row>
                </Col>
            </Row>
        </>
    )
}

export default Project;