import { Container, Row } from 'react-bootstrap';
import MyDiplomasAndCertifications from '../MyDiplomasAndCertifications/MyDiplomasAndCertifications';
import MyExperiences from '../MyExperiences/MyExperiences';
import Skills from '../Skills/Skills';
import MyProjects from '../MyProjects/MyProjects';

function Resume() {
    return (
        <Container>
            <Row className="flex-wrap">
                <MyDiplomasAndCertifications/>
                <MyExperiences/>
                <Skills/>
                <MyProjects/>
            </Row>
        </Container>
    )
}

export default Resume;