import { Row, Col, Image } from "react-bootstrap";
import "./Job.css";
export interface JobProps {
    JobDescription: string[],
    BulletPointDescription: string[]
    JobTitle: string,
    LogoPath: string
    StartingDate: string,
    EndingDate: string,
    index: number
}

const default_logo_col_props = {
    sm:2
}
const default_job_description_col_props = {
    sm:8
}

const default_time_of_tenure_col_props = {
    sm:2,
}

function Job(props: JobProps) {
    const logoFolderPath = './jobs-logo/'
    const logo = require(`${logoFolderPath}${props.LogoPath}`);
    const backgroundStyle = props.index % 2 === 0 ? "job-background-even" : "job-background-odd";

    return (
        <>
            <Row className={`job-title text-center ${backgroundStyle}`}>
                {props.JobTitle}
            </Row>
            <Row >
                <Col {...default_logo_col_props} className={`d-flex align-items-center ${backgroundStyle}`}>
                    <Image className="bg-transparent" fluid src={logo}/>
                </Col>
                <Col {...default_job_description_col_props} className={`text ${backgroundStyle} job-description`}>
                    {props.JobDescription.map((paragraph, i) => {
                        return <div key={i}>{paragraph}<br/></div>
                    })}
                    <ul className="mt-4">
                        {props.BulletPointDescription.map((paragraph, i) => {
                            return <li className="bullet-point" key={i}>{paragraph}</li>
                        })}
                    </ul>
                </Col>
                <Col {...default_time_of_tenure_col_props} className={`d-flex align-items-center justify-content-start text textTimeTenure ${backgroundStyle}`} >
                    {props.StartingDate} - {props.EndingDate}
                </Col>
            </Row>
        </>
    )
}

export default Job;