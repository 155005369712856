import './App.css';
import 'bootstrap/dist/css/bootstrap.css'
import NavigationBar from './NavigationBar/NavigationBar';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from './HomePage/HomePage';
import Books from './MyBooks/MyBooks';
import Resume from './Resume/Resume';

function App() {
  return (
    <BrowserRouter>
      <NavigationBar/>
      <Routes>
        <Route path="/" element={<HomePage/>}/>
        <Route path="/HomePage" element={<HomePage/>}/>
        <Route path="/Resume" element={<Resume/>}/>
        <Route path="/Books" element={<Books/>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
