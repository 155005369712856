import { Container, Row } from "react-bootstrap";
import books from "./Books.json";
import Book from "../Book/Book";

function MyBooks() {
  return (
    <Container className="mt-5 mb-5 contoured">
      <Row className="text px-0 padding-top">
        <h3 className="section-title MyBooksText">
          Here is a non-exhaustive list of books that I have read and enjoyed. If you think I should read a book, please let me know!
        </h3>
      </Row>
      <Row>
        { 
          books.map((book, i) => {
            return <Book key={i} {...book}></Book>
          })
        }
      </Row>
    </Container>
  );
}

export default MyBooks;