import { Container, Col, Row, Image} from "react-bootstrap";
import MyPicture from "../IMG_2031.jpg"
import "@fontsource/roboto";
import "./HomePage.css"
function HomePage() {
    return (
        <Container className="h-100">
            <Row className="introContainer rounded align-items-center">
                <Col sm={4} className="d-flex justify-content-start px-0">
                    <Image className="img-fluid" src={MyPicture} thumbnail ></Image>
                </Col>
                <Col sm={8} className="d-flex flex-column justify-content-center align-items-center">
                        <Row className="intro-text">
                        Welcome to my corner of the digital universe! I'm a recent graduate from Polytechnique Montreal with a bachelor's degree in Software Engineering, and I'm currently working at Microsoft.<br/><br/>

                        Beyond the realm of coding, I lead a life enriched by diverse interests. When I'm not immersed in lines of code, you'll find me lost in the pages of a compelling book, scaling rock faces as an avid climber, traversing scenic hiking trails, gliding on my skateboard through urban landscapes, or carving fresh powder on snow-covered slopes during winter.<br/><br/>

                        This website is a window into my world, where I'll share insights from my software engineering journey, thoughts on the latest tech trends, and reflections on my outdoor adventures. Join me as I explore the intersection of technology and the great outdoors, and feel free to drop by to discuss software or scaling heights. Thanks for stopping by!<br/><br/>
                        </Row>
                        <Row className="jsonInfo rounded d-flex justify-content-center align-items-center"> 
                            <pre><code>
                            {
`{    
    "name": "Julien Desalliers",
    "email": "<last_name>.<first_name>@gmail.com",
    "degree": "Bachelor of Software Engineering",
    "school": "Polytechnique Montreal"
}`
                            }
                            </code></pre>
                        </Row>
                </Col>
            </Row>
        </Container>
    );
}

export default HomePage;