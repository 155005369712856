import { Container, Row } from "react-bootstrap";
import Projects from "./MyProjects.json";
import Project from "../Project/Project";

function MyProjects() {
    return (
        <Container className="mt-5 mb-5 contoured">
          <Row className="text px-0 padding-top">
            <h1 className="section-title">My Projects</h1>
          </Row>
          { 
            Projects.map((project , i) => {
              project.index = i
              return <Project key={i} {...project}></Project>
            })
          }
        </Container>
    );
}

export default MyProjects;